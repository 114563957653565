@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
body {
    margin: 0;
    font-family: 'Roboto', sans-serif;    

}

.app {
    background-color: rgb(0, 2, 44);
    height: 100vh;
    width: 100vw;
    color: #fff;
}

.submit-button {
    position: absolute;
    bottom: 30px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.button {
    padding: 15px 20px;
    border-radius: 8px;
    color: rgb(0, 2, 44);
    background-color: #fff;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;    
    font-weight: 300;
    transition: 0.3s;
}

@media only screen and (max-width: 414px) {
    .app {
      font-size: 48px;
    }
  }

.lyrics-wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.lyrics {
    padding: 30px;
    font-weight: 700;
    font-size:72px;
}

.song-info {
    font-size: 18px;
    align-self: flex-start;
    padding-left: 30px;
    opacity: 0;
    transition: 0.3s;
}

.song-title {
    font-style: italic;
}

.show {
    opacity: 1;
}

.letter {
    color: white;
    display: inline;
}

@-webkit-keyframes letters {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }

@keyframes letters {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }


.input-section {
    margin-bottom: 20px;
}

.input {
    width: 100%;
    max-width: 300px;
}

.error-text {
    color: red;
    margin-bottom: 10px;
}
